<template>
	<w-layout>
		<DocumentZoomButton direction="in" bottom />
		<DocumentZoomButton direction="out" bottom />
		<DocumentZoomButton v-if="!conditionalDisplay.isMobileApp" direction="in" :fit="true" bottom />
		<DocumentZoomButton v-if="!conditionalDisplay.isMobileApp" direction="out" :fit="true" bottom />
	</w-layout>
</template>

<script>

export default {
	name: 'DocumentZoomButtons',
	components: {
		DocumentZoomButton: () => import('@/components/Documents/Preview/PreviewToolBar/Actions/DocumentZoomButton'),
	},
	inject: [
		'conditionalDisplay',
	]
}
</script>
